import React, { useEffect, useRef } from 'react';
import { store, view } from '@risingstack/react-easy-state';
import KinesisVideo from 'aws-sdk/clients/kinesisvideo';
import KinesisVideoSignalingChannels from 'aws-sdk/clients/kinesisvideosignalingchannels';
import { SignalingClient } from 'amazon-kinesis-video-streams-webrtc';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Box from '@material-ui/core/Box'; // Import Box component for layout

import Widget from './widget.js';
import useStyles from './material-ui-styles.js';
import './VideoPlayer.css';
import logo from '../logo.svg'; // Make sure the logo is imported here if not already



// Used to determine / validate options in form components:
const OPTIONS = {
  TRAVERSAL: {
    STUN_TURN: 'stunTurn',
    TURN_ONLY: 'turnOnly',
    DISABLED: 'disabled'
  },
  ROLE: {
    MASTER: 'MASTER',
    VIEWER: 'VIEWER'
  },
  RESOLUTION: {
    WIDESCREEN: 'widescreen',
    FULLSCREEN: 'fullscreen'
  }
};

// Stores state across components (react-easy-state is super easy to use!)
const state = store({
  // These are config params set by the user:
  accessKey: 'AKIA3PO4DLNAXCNHYIK4',
  secretAccessKey: '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',
  sessionToken: '',
  region: 'eu-west-2',
  role: OPTIONS.ROLE.VIEWER,
  channelName: 'TestStr',
  clientId: getRandomClientId(),
  endpoint: null,
  sendVideo: true,
  sendAudio: false,
  openDataChannel: false,
  resolution: OPTIONS.RESOLUTION.WIDESCREEN,
  natTraversal: OPTIONS.TRAVERSAL.STUN_TURN,
  useTrickleICE: true,
  messageToSend: '',
  playerIsStarted: false,

  // These are set when user starts video; a few of them are only used when you start the stream as MASTER:
  signalingClient: null,
  localStream: null,
  localView: null,
  remoteView: null,
  dataChannel: null,
  peerConnectionStatsInterval: null,
  peerConnectionByClientId: {},
  dataChannelByClientId: [],
  receivedMessages: '',

});

//------------------------------------------------------------------------------
const KinesisWebRTC = view(() => {

  // In order to modify properties of our <video> components, we need a reference
  // to them in the DOM; first, we declare set them up with the useRef hook. 
  // Later, when we render the <VideoPlayers/> component, we include this reference
  // in the component definition. Finally, we can reference the object properties
  // by state.localView.current.<PROPERTY>:
  state.localView = useRef(null);
  state.remoteView = useRef(null);

  // When widget first loads, get saved state values from localStorage:
  useEffect(() => {
    for (const [key] of Object.entries(state)) {
      var localStorageValue = localStorage.getItem(`kvs-widget-${key}`);
      if (localStorageValue) {
        // Convert true or false strings to boolean (needed for checkboxes):
        if (["true", "false"].includes(localStorageValue)) {
          localStorageValue = localStorageValue === "true";
        }
        //console.log(`Setting ${key} = `, localStorageValue);
        state[key] = localStorageValue;
      }
    }
  }, []);

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center', // Vertically center
      alignItems: 'center', // Horizontally center
      height: '100vh', // Full height of the viewport
      backgroundImage: 'url("/assets/backgroundImage.webp")', // Assuming the image is in the public/assets directory
      backgroundSize: 'cover', // Cover the entire area
      backgroundPosition: 'center', // Center the background image
      fontFamily: '"Roboto", sans-serif', // Recommended font
      position: 'relative', // For positioning footer at the bottom
    }}>

  <h2 style={{
    position: 'fixed', // Stick to the top of the viewport
    top: 0, // No space from the top edge
    left: 0, // Align to the left edge
    right: 0, // Stretch across to the right edge
    fontSize: '22px', // Recommended size for mobile, adjust as needed
    color: '#fff', // Assuming a dark background image, use a light color for contrast
    textAlign: 'center',
    padding: '20px',
    backgroundColor: 'rgba(1,50,32,0.7)', // Adds a dark semi-transparent background for better readability
    borderRadius: '1px 1px 0 0', // Soften the upper edges, flat at the bottom where it meets the content
    zIndex: 1000, // Ensure it stays on top of other content
    margin: '0 auto', // Center if there's a specific width
    width: '100%', // Take the full width of the viewport
    }}>
  Video Stream Analysis Platform
  </h2>
      <ConfigurationForm/>
      <br /><br />
      {state.playerIsStarted ? <VideoPlayers /> : null }

      {/* Footer */}
      <footer style={{
        textAlign: 'center',
        padding: '0px',
        marginTop: 'auto', // Push to the bottom
        backgroundColor: 'rgba(1,50,32,0.8)',
        
        color: '#FFFFFF', // Example text color
        position: 'fixed', // Make footer always visible at the bottom
        left: 0,
        bottom: 0,
        width: '100%',
      }}>
        
        <p>  ROGON GmbH | Football Live Stream Analysis Demo &copy; {new Date().getFullYear()} All Rights Reserved
</p>
      </footer>
    </div>
  );
  
});

//------------------------------------------------------------------------------
const ConfigurationForm = view(() => {

  const classes = useStyles();
  return (
    <Widget>
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" minHeight="10vh">
     
      <h3>Connect to a Live Event</h3>
     
      <br /><br />
      {state.playerIsStarted ?
        <Button 
          id="stopPlayer" 
          variant="contained" 
          style={{ backgroundColor: 'rgba(250, 216, 89)', color: '#000' }} // Custom yellow background, black text
          onClick={stopPlayer}
        >
          Stop Video Stream
        </Button>
        :
        <Button 
          id="startPlayer" 
          variant="contained" 
          style={{ backgroundColor: 'rgba(250, 216, 89)', color: '#000' }} // Custom yellow background, black text
          onClick={startPlayer}
        >
          Start Video Stream
        </Button>
      }
       </Box>
    </Widget>
  );
  
});

//------------------------------------------------------------------------------
const VideoPlayers = view(() => {

  return (
    <Widget>
      <div id="video-players" className="d-none">
        <div className="row">

          <div className="col">
            <h5>Live Channel UK London</h5>
            <div className="video-container">
              <video
              className="return-view"
              ref={state.remoteView}
              autoPlay
              playsInline
              controls
              />
            </div>
            {/* Apply styles directly to this div to make the video effectively invisible */}
            <div className="video-container" style={{ width: '0', height: '0', overflow: 'hidden' }}>
              <video
                className="return-view"
                ref={state.localView}
                autoPlay
                playsInline
                controls
              />
            </div>


          </div>
        </div>

      

      </div>
      
      
    </Widget>
  );
  
});

//------------------------------------------------------------------------------
function startPlayer() {

  state.playerIsStarted = true;
  console.log(`role is '${state.role}'`)
  
  startPlayerForViewer();
  
}


//------------------------------------------------------------------------------
async function startPlayerForViewer() {
  
  // Create KVS client
  console.log('Created KVS client...');
  const kinesisVideoClient = new KinesisVideo({
    region: state.region,
    endpoint: state.endpoint || null,
    correctClockSkew: true,
    accessKeyId: state.accessKey,
    secretAccessKey: state.secretAccessKey,
    sessionToken: state.sessionToken || null
  });

  // Get signaling channel ARN
  console.log('Getting signaling channel ARN...');
  const describeSignalingChannelResponse = await kinesisVideoClient
    .describeSignalingChannel({
        ChannelName: state.channelName,
    })
    .promise();
  
  const channelARN = describeSignalingChannelResponse.ChannelInfo.ChannelARN;
  console.log('[VIEWER] Channel ARN: ', channelARN);

  // Get signaling channel endpoints:
  console.log('Getting signaling channel endpoints...');
  const getSignalingChannelEndpointResponse = await kinesisVideoClient
    .getSignalingChannelEndpoint({
        ChannelARN: channelARN,
        SingleMasterChannelEndpointConfiguration: {
            Protocols: ['WSS','HTTPS'],
            Role: state.role, //roleOption.MASTER
        },
  })
  .promise();
  
  const endpointsByProtocol = getSignalingChannelEndpointResponse.ResourceEndpointList.reduce((endpoints, endpoint) => {
    endpoints[endpoint.Protocol] = endpoint.ResourceEndpoint;
    return endpoints;
  }, {});  
  console.log('[VIEWER] Endpoints: ', endpointsByProtocol);

  // Create Signaling Client
  console.log(`Creating signaling client...`);
  state.signalingClient = new SignalingClient({
    channelARN,
    channelEndpoint: endpointsByProtocol.WSS,
    role: state.role, //roleOption.MASTER
    region: state.region,
    systemClockOffset: kinesisVideoClient.config.systemClockOffset,
    clientId: state.clientId,
    credentials: {
      accessKeyId: state.accessKey,
      secretAccessKey: state.secretAccessKey,
      sessionToken: state.sessionToken || null
    }
  });
  
  // Get ICE server configuration
  console.log('Creating ICE server configuration...');
  const kinesisVideoSignalingChannelsClient = new KinesisVideoSignalingChannels({
    region: state.region,
    endpoint: endpointsByProtocol.HTTPS,
    correctClockSkew: true,
    accessKeyId: state.accessKey,
    secretAccessKey: state.secretAccessKey,
    sessionToken: state.sessionToken || null
  });

  console.log('Getting ICE server config response...');
  const getIceServerConfigResponse = await kinesisVideoSignalingChannelsClient
        .getIceServerConfig({
            ChannelARN: channelARN,
        })
    .promise();
  
  const iceServers = [];
  if (state.natTraversal === OPTIONS.TRAVERSAL.STUN_TURN) {
    console.log('Getting STUN servers...');
    iceServers.push({ urls: `stun:stun.kinesisvideo.${state.region}.amazonaws.com:443` });
  }
  
  if (state.natTraversal !== OPTIONS.TRAVERSAL.DISABLED) {
    console.log('Getting TURN servers...');
    getIceServerConfigResponse.IceServerList.forEach(iceServer =>
      iceServers.push({
        urls: iceServer.Uris,
        username: iceServer.Username,
        credential: iceServer.Password,
      }),
    );
  }
  
  const configuration = {
    iceServers,
    iceTransportPolicy: (state.natTraversal === OPTIONS.TRAVERSAL.TURN_ONLY) ? 'relay' : 'all',
  };

  const resolution = (state.resolution === OPTIONS.TRAVERSAL.WIDESCREEN) ? { width: { ideal: 1280 }, height: { ideal: 720 } } : { width: { ideal: 640 }, height: { ideal: 480 } };

  const constraints = {
      video: state.sendVideo ? resolution : false,
      audio: state.sendAudio,
  };

  state.peerConnection = new RTCPeerConnection(configuration);
  if (state.openDataChannel) {
      console.log(`Opened data channel with MASTER.`);
      state.dataChannel = state.peerConnection.createDataChannel('kvsDataChannel');
      state.peerConnection.ondatachannel = event => {
        event.channel.onmessage = (message) => {
          const timestamp = new Date().toISOString();
          const loggedMessage = `${timestamp} - from MASTER: ${message.data}\n`;
          console.log(loggedMessage);
          state.receivedMessages += loggedMessage;

        };
      };
  }

  // Poll for connection stats
  state.peerConnectionStatsInterval = setInterval(
    () => {
      state.peerConnection.getStats().then(onStatsReport);
    }, 100
  );

  /// REVIEW BELOW HERE

  state.signalingClient.on('open', async () => {
    console.log('[VIEWER] Connected to signaling service');

    // Get a stream from the webcam, add it to the peer connection, and display it in the local view.
    // If no video/audio needed, no need to request for the sources. 
    // Otherwise, the browser will throw an error saying that either video or audio has to be enabled.
    if (state.sendVideo || state.sendAudio) {
        try {
            state.localStream = await navigator.mediaDevices.getUserMedia(constraints);
            state.localStream.getTracks().forEach(track => state.peerConnection.addTrack(track, state.localStream));
            state.localView.current.srcObject = state.localStream;
        } catch (e) {
            console.error('[VIEWER] Could not find webcam');
            return;
        }
    }

    // Create an SDP offer to send to the master
    console.log('[VIEWER] Creating SDP offer');
    await state.peerConnection.setLocalDescription(
        await state.peerConnection.createOffer({
            offerToReceiveAudio: false,
            offerToReceiveVideo: true,
        }),
    );

    // When trickle ICE is enabled, send the offer now and then send ICE candidates as they are generated. Otherwise wait on the ICE candidates.
    if (state.useTrickleICE) {
        console.log('[VIEWER] Sending SDP offer');
        state.signalingClient.sendSdpOffer(state.peerConnection.localDescription);
    }
    console.log('[VIEWER] Generating ICE candidates');
});

state.signalingClient.on('sdpAnswer', async answer => {
    // Add the SDP answer to the peer connection
    console.log('[VIEWER] Received SDP answer');
    await state.peerConnection.setRemoteDescription(answer);
});

state.signalingClient.on('iceCandidate', candidate => {
    // Add the ICE candidate received from the MASTER to the peer connection
    console.log('[VIEWER] Received ICE candidate');
    state.peerConnection.addIceCandidate(candidate);
});

state.signalingClient.on('close', () => {
    console.log('[VIEWER] Disconnected from signaling channel');
});

state.signalingClient.on('error', error => {
    console.error('[VIEWER] Signaling client error: ', error);
});

// Send any ICE candidates to the other peer
state.peerConnection.addEventListener('icecandidate', ({ candidate }) => {
    if (candidate) {
        console.log('[VIEWER] Generated ICE candidate');

        // When trickle ICE is enabled, send the ICE candidates as they are generated.
        if (state.useTrickleICE) {
            console.log('[VIEWER] Sending ICE candidate');
            state.signalingClient.sendIceCandidate(candidate);
        }
    } else {
        console.log('[VIEWER] All ICE candidates have been generated');

        // When trickle ICE is disabled, send the offer now that all the ICE candidates have ben generated.
        if (!state.useTrickleICE) {
            console.log('[VIEWER] Sending SDP offer');
            state.signalingClient.sendSdpOffer(state.peerConnection.localDescription);
        }
    }
});

// As remote tracks are received, add them to the remote view
state.peerConnection.addEventListener('track', event => {
    console.log('[VIEWER] Received remote track');
    if (state.remoteView.current.srcObject) {
        return;
    }
    state.remoteStream = event.streams[0];
    state.remoteView.current.srcObject = state.remoteStream;
});

console.log('[VIEWER] Starting viewer connection');
state.signalingClient.open();
  
}

//------------------------------------------------------------------------------
function stopPlayer() {

  state.playerIsStarted = false;
  console.log(`role is '${state.role}'`)
  
  stopPlayerForViewer();
  
}

//------------------------------------------------------------------------------
function stopPlayerForViewer() {

  console.log('[VIEWER] Stopping viewer connection');
  if (state.signalingClient) {
    state.signalingClient.close();
    state.signalingClient = null;
  }

  if (state.peerConnection) {
    state.peerConnection.close();
    state.peerConnection = null;
  }

  if (state.localStream) {
    state.localStream.getTracks().forEach(track => track.stop());
    state.localStream = null;
  }

  if (state.remoteStream) {
    state.remoteStream.getTracks().forEach(track => track.stop());
    state.remoteStream = null;
  }

  if (state.peerConnectionStatsInterval) {
      clearInterval(state.peerConnectionStatsInterval);
      state.peerConnectionStatsInterval = null;
  }

  if (state.localView) {
    state.localView.current.srcObject = null;
  }

  if (state.remoteView) {
    state.remoteView.current.srcObject = null;
  }

  if (state.dataChannel) {
    state.dataChannel = null;
  }
}

//------------------------------------------------------------------------------
function onStatsReport(report) {
  // TODO: Publish stats
}

//------------------------------------------------------------------------------
function sendMessage() {
  if (state.role === OPTIONS.ROLE.MASTER) {
    Object.keys(state.dataChannelByClientId).forEach(clientId => {
      try {
        state.dataChannelByClientId[clientId].send(state.messageToSend);
        console.log(`Message sent to client ${clientId}: ${state.messageToSend}`);
      } catch (e) {
        console.error('[MASTER] Send DataChannel: ', e.toString());
      }
  });
  }
  else {
    if (state.dataChannel) {
      try {
        state.dataChannel.send(state.messageToSend);
        console.log(`Message sent to master: ${state.messageToSend}`);
      } catch (e) {
          console.error('[VIEWER] Send DataChannel: ', e.toString());
      }
  }
  }
}

//------------------------------------------------------------------------------
function updateState(key, value) {
  state[key] = value;
  var localKey = `kvs-widget-${key}`;
  //console.log(`Setting ${localKey} = `, value);
  localStorage.setItem(localKey, value);
}

//------------------------------------------------------------------------------
function getRandomClientId() {
  return Math.random()
      .toString(36)
      .substring(2)
      .toUpperCase();
}

export default KinesisWebRTC; 